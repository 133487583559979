@import '/home/circleci/repo/get-started/src/overrides.scss';
@import '../global-constants';

$positive-background: var(
  --medkit-alert-positive,
  $traffic-light-positive-green-400
);
$positive-text: var(--medkit-alert-on-positive, $white100);
$warning-background: var(
  --medkit-alert-warning,
  $traffic-light-warning-yellow-200
);
$warning-text: var(--medkit-alert-on-warning, $grey-900);
$error-background: var(--medkit-alert-error, $traffic-light-risk-red-500);
$error-text: var(--medkit-alert-on-error, $white100);
$info-background: var(--medkit-alert-info, $grey-100);
$info-text: var(--medkit-alert-on-info, $grey-900);

.alert {
  display: flex;
  align-items: center;
  padding: $space--m;
  border-radius: $border-radius-small;

  &--info {
    background: $info-background;
    color: $info-text;

    & svg {
      fill: $info-text;
    }
  }

  &--positive {
    background-color: $positive-background;
    color: $positive-text;

    & svg {
      fill: $positive-text;
    }
  }

  &--warning {
    background-color: $warning-background;
    color: $warning-text;

    & svg {
      fill: $warning-text;
    }
  }

  &--error {
    background-color: $error-background;
    color: $error-text;

    & svg {
      fill: $error-text;
    }
  }

  &--loading {
    @include shimmerAnimation;
    min-height: 3.375rem;
  }
}

.icon {
  display: flex;
  align-items: center;
  margin-right: $space--m;
}
