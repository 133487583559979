@import '/home/circleci/repo/get-started/src/overrides.scss';
/* stylelint-disable declaration-no-important */

@import 'components/constants';

.cookieNotice {
  background-color: $grey10;
  bottom: 0;
  border-top: 1px solid $grey-light;
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  text-align: center;
  transition: transform $transition-time $transition-ease;
  z-index: 30; // same as Alert, below Header/Navigation, above everything else

  // disabled animation dependant on user preference
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &--hidden {
    transform: translateY(100%);
  }

  &__inner {
    margin: 0 auto;
    max-width: $extra-large-breakpoint;
    text-align: left;

    @include tablet-mini {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__content {
    margin-bottom: 1rem;

    @include tablet-mini {
      margin-bottom: 0;
      padding-right: 1rem;
    }

    p {
      padding: 0;
      margin: 0;
    }

    a {
      font-weight: 500;
    }
  }
}

.buttons {
  display: flex;
}

.button {
  min-width: 0;
  flex: 1;
}

@include tablet-mini {
  .buttons {
    display: block;
  }

  .button {
    min-width: 160px;
    flex: 0;
  }

  .declineButton {
    margin-left: 0 !important;
    margin-top: $space--s;
  }
}
