@import '/home/circleci/repo/get-started/src/overrides.scss';
@import 'components/constants.scss';

.heartIcon {
  height: 1.5rem;
  margin-left: 5px;
}

.babylonBrandingCopy {
  color: $primary;
}

.babylonLogo {
  max-width: 100px;
  margin-top: -3px; // negative margin to line logo type up with copy
}
