@import '/home/circleci/repo/get-started/src/overrides.scss';
.flashMessage {
  width: 540px;
  position: fixed;
  left: 50%;
  top: 0;
  opacity: 0;
  transform: translate(-50%, 0);
  animation-duration: 8s;
  // Alerts need to appear on top of everything, including modals
  z-index: 50000;
  animation-name: revealAndHide;

  /* stylelint-disable order/order  */
  @include less-than-tablet {
    left: 0;
    width: 100vw;
    transform: translate(0, -100%);
  }
  /* stylelint-enable  */
}

@keyframes revealAndHide {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }

  15%,
  85% {
    transform: translate(-50%, 60px);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  @keyframes revealAndHide {
    0% {
      transform: translate(-50%, 0);
      opacity: 1;
    }

    99.9% {
      transform: translate(-50%, 0);
      opacity: 1;
    }

    100% {
      transform: translate(-50%, -100%);
    }
  }
}

@include less-than-tablet {
  @keyframes revealAndHide {
    0% {
      transform: translate(0, -100%);
      opacity: 0;
    }

    15%,
    85% {
      transform: translate(0, 0);
      opacity: 1;
    }

    100% {
      transform: translate(0, -100%);
      opacity: 0;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    @keyframes revealAndHide {
      0% {
        transform: translate(0, 0);
        opacity: 1;
      }

      99.9% {
        transform: translate(0, 0);
        opacity: 1;
      }

      100% {
        transform: translate(0, -100%);
      }
    }
  }
}
