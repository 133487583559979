@import '/home/circleci/repo/get-started/src/overrides.scss';
.title {
  font-size: 24px;
}

.errorContainer {
  text-align: center;
  max-width: 500px;
  margin: auto;
}
