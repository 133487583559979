@import '/home/circleci/repo/get-started/src/overrides.scss';
@import '../global-constants';

$dialog-background: var(--medkit-dialog-background, $sterile-white);
$dialog-overlay-background: var(
  --medkit-dialog-overlay-background,
  rgba($note-black, 0.6)
);

.dialogOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: auto;
  background-color: $dialog-overlay-background;
}

.dialogContent {
  width: 100%;
  height: 100%;
  min-width: 300px;
  padding: 2rem;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $dialog-background;
  outline: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // enable elastic scrolling for iOS Safari 12

  @include breakpoint(medium) {
    width: 600px;
    max-height: 90vh;
    min-height: 10vh;
    height: auto;
  }
}

.modalHeader {
  display: flex;

  &__button {
    display: flex;
    margin: auto;
  }

  &__closeBtn {
    margin-right: 0;
  }

  &__backBtn {
    margin-left: 0;
  }
}
