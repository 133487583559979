@import '/home/circleci/repo/get-started/src/overrides.scss';
@import 'components/constants.scss';

.appsInfoWrapper,
.configurableAppsInfoWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;

  @include tablet-mini {
    text-align: start;
    flex-direction: row;
  }
}

.configurableAppsInfoWrapper {
  margin: 0 auto;
  max-width: 700px;
}

.leftBlock,
.rightBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 30px 0;
  align-items: center;

  @include tablet-mini {
    width: 50%;
  }
}

.leftBlock {
  @include tablet-mini {
    align-items: flex-start;
  }
}

.appImage {
  width: 300px;
}

.callToAction {
  max-width: 300px;
}

.storeBadge {
  display: inline-block;
  margin: 10px 5px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  @include tablet-mini {
    margin: 10px 10px 10px 0;
  }
}

.iconsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.linkContainer {
  margin: 10px 0;
}
