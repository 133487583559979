@import '/home/circleci/repo/get-started/src/overrides.scss';
.loginHeader {
  height: 65px;
  padding: 10px 20px;
  border-bottom: 1px solid#ddd;

  .logo {
    display: inline-block;
    margin: 0;
    height: 40px;
    line-height: 40px;

    img {
      max-height: 100%;
    }
  }
}

.babylon-logo {
  width: 100px;
}

.ask-a-and-e-logo {
  display: block;
  max-height: 40px;
}

.babylon-brazil-einstein-logo {
  display: block;
  max-height: 40px;
  max-width: 200px;
}
