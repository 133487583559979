@import '/home/circleci/repo/get-started/src/overrides.scss';
@import 'components/constants.scss';
@import '~@babylon/medkit/src/Link/link.module.scss';

// We dangerously set inner html, so we need to override by element
#noticePage {
  font-family: $font-family;

  a {
    @include medkit-link-styles;
  }

  h1 {
    color: $grey-extra-dark;
    display: block;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 0 1.5rem 0;

    @include tablet-mini {
      font-size: 2.5rem;
    }
  }
}
