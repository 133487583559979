@import '/home/circleci/repo/get-started/src/overrides.scss';
@import 'components/constants.scss';

.partnerBrandingContainer {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.partnerBranding {
  color: $primary;

  @include tablet {
    display: flex;
    align-items: center;
  }
}

.partnerLogo {
  max-height: 60px;

  @include tablet {
    margin-left: 6px;
  }
}
